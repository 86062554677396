import {
  Typography,
  Headline as NeuiHeadline,
  sandTheme,
  interaction_arrows_arrowRight,
} from '@neui/styleguide-commerzbank';
import { SimpleGrid, VStack } from '@neui/layout';
import { GA4TrackTeaserCtaClick } from '@utils/tracking';
import { useMakeLink } from '@components/Link';
import { TeaserCard } from '@components/neui-components/molecules/TeaserCard';
import { SandSection } from '@components/OBServices/OBServices';
import { StyledIconLink } from 'page-templates/FkIndexPage';
import { InView } from 'react-intersection-observer';
import { handleInViewChange } from '@utils/helpers/misc';

import { useTracker } from '../../utils/snowplowTracking';

export type MostReadArticleType = {
  title: string;
  slug: string;
  excerpt: string;
};

export type MostReadArticlesProps = {
  trackButtonClick: ReturnType<typeof useTracker>['trackButtonClick'];
  mostReadArticles: MostReadArticleType[];
};

export const MostReadArticles = ({
  trackButtonClick,
  mostReadArticles,
}: MostReadArticlesProps) => {
  const makeLink = useMakeLink();

  return (
    <InView
      onChange={handleInViewChange('Gerade beliebt', 2, 4)}
      triggerOnce={true}
    >
      {({ ref }) => (
        <SandSection theme={'sand'} className={sandTheme} ref={ref}>
          <NeuiHeadline
            type="h3"
            renderAs="h2"
            textAlign={'left'}
            css={{ alignSelf: 'flex-start' }}
          >
            Gerade beliebt:
          </NeuiHeadline>

          <SimpleGrid
            spacing={{ base: '$space$component-8' }}
            columns={{ base: 1, xs: 1, sm: 2, md: 3 }}
          >
            {mostReadArticles.map((article, index) => {
              const onClick = () => {
                trackButtonClick?.(article.slug, 'top_article', []);
                GA4TrackTeaserCtaClick(
                  'CTA Card',
                  article.title,
                  'Mehr erfahren',
                  makeLink({
                    href: article.slug,
                    absoluteUrl: true,
                    alwaysPrependBasePath: true,
                  }),
                  'Gerade beliebt',
                  2,
                  4,
                );
              };
              return (
                <TeaserCard
                  key={index}
                  href={article.slug}
                  onClick={onClick}
                  hover
                  footer={
                    <StyledIconLink
                      iconPosition="right"
                      animationDirection="none"
                      renderAs={Typography}
                      icon={interaction_arrows_arrowRight}
                      data-cy={'top-article'}
                    >
                      Mehr erfahren
                    </StyledIconLink>
                  }
                >
                  <VStack spacing={{ base: 12, md: 16 }}>
                    <Typography
                      size={4}
                      weight={'medium'}
                      role="heading"
                      aria-level={3}
                    >
                      {article.title}
                    </Typography>
                    <Typography textAlign={'left'}>
                      {article.excerpt}
                    </Typography>
                  </VStack>
                </TeaserCard>
              );
            })}
          </SimpleGrid>
        </SandSection>
      )}
    </InView>
  );
};
